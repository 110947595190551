<template>
  <v-main>
    <v-container>
      <h1 class="display-1">Water</h1>
      <div>
        <div>
          <p>
            Water is the most important element for life. We travel the solar
            system looking for signs of water and take that to be signs of life.
            <a
              href="https://www.usgs.gov/special-topic/water-science-school/science/water-you-water-and-human-body?qt-science_center_objects=0#qt-science_center_objects"
              target="_blank"
              >Our bodies are 60% water</a
            >, and we need to drink plenty of clean water almost daily to
            survive.
          </p>
        </div>
        <div>
          <p>But water can become contaminated, from three major sources:</p>
          <ul>
            <li>Human activity from septic system</li>
            <li>Stormwater runoff from roads</li>
            <li>Fertilizer</li>
          </ul>
        </div>
        <div class="mt-4">
          <p>
            In Truro, our water is contained in our aquifer below ground –
            that’s why it’s called “groundwater.” The sole source of water for
            that aquifer is rain. Rain recharges the aquifer, and our wells pump
            from it. Eventually that rainwater finds its way to the bay or the
            ocean, but along the way it can carry contaminants from one place to
            another on its way to the bay or ocean. And those contaminants can
            end up in your well.
          </p>
        </div>

        <div>
          <p>
            The contaminants in water increase your health risks, as has been
            proven by numerous scientific studies over the past few decades. To
            safeguard your health, you should have your water tested annually.
          </p>
        </div>
        <div>
          <p>
            The Water feature of the Peeps Portal is designed to let you easily
            track your water quality test results. And by doing so, Peeps will
            be able to help you interpret your test results. It’s a very simple
            entry of a few pieces of data from your water test, but retaining
            that data will start building insights into what is happening
            beneath ground in your drinking water well.
          </p>
        </div>
        <div class="nitrate--text text-h5 text-center my-2">
          Learn more about <nitrate-button text="Nitrates"></nitrate-button> in
          Water
        </div>
        <div>
          RESOURCES:
          <a
            href="https://www.capecodcommission.org/our-work/drinkingwater/"
            target="_blank"
            class="decoration-none hover-underline"
            >Drinking Water and Groundwater | Cape Cod Commission</a
          >
        </div>
      </div>
    </v-container>
  </v-main>
</template>

<script>
export default {
  metaInfo: {
    title: 'About Water'
  }
}
</script>

<style></style>
